export const BREAKPOINT = {
  XLARGE_MAX: '1324px',
  XLARGE_MIN: '1200px',

  LARGE_MAX: '1199px',
  LARGE_MIN: '1024px',

  MEDIUM_MAX: '1023px',
  MEDIUM_AVG: '992px',
  MEDIUM_MIN: '768px',

  SMALL_MAX: '767px',
  SMALL_AVG: '480px',
  SMALL_MIN: '320px',

  XSMALL_MAX: '319px',
  XSMALL_MIN: '0px',
  XSMALL_AVG: '375px',

  XXSMALL_MAX: '329px'
}
export const COLOR = {
  BLUE1: '#0A4386', // BLUE_DARK
  BLUE2: '#0B6EFD', // BLUE_LIGHT
  BLUE3: '#126BC5', // BLUE
  BLUE4: '#0092FF', // BLUE_LIGHTER
  BLUE5: '#A9D5ED',
  BLUE6: '#35334e',
  BLUE7: '#7FBAC8',
  BLUE8: '#115880',
  BLUE9: '#B6D1CF',
  BLUE10: '#2B6EB6',
  BLUE11: '#0060F0',
  BLUE12: '#A4D9FB',
  BLUE13: '#1570A3',
  BLUE14: '#D1EEFF', // SUPER_LIGHT_BLUE
  BLUE15: '#0055EE',
  BLUE16: '#0053AF',
  BLUE17: '#005EB8',
  BLUE18: '#004692', // BLUE_MED_DARK
  BLUE19: '#EBF7FF',
  BLUE20: '#EAF6FF',
  BLUE21: '#2C86F2',
  BLUE22: '#0091DA',
  GRAY1: '#717171', // GRAY_DARK
  GRAY2: '#666666', // GRAY_DARK2
  GRAY3: '#414042', // GRAY_DARKER
  GRAY4: '#D6D6D6', // GRAY_LIGHT
  GRAY5: '#CCCCCC', // GRAY_LIGHT2
  GRAY6: '#F7F7F7', // GRAY_LIGHTER
  GRAY7: '#F2F2F2', // GRAY_LIGHTER2
  GRAY8: '#E5E5E5', // GRAY_LIGHTER3
  GRAY9: '#F9F6F3', // GRAY_LIGHTEST
  GRAY10: '#959595', // GRAY
  GRAY11: '#979797', // GRAY2
  GRAY12: '#EDEDED', // GRAY3
  GRAY13: '#EDE9E3', // GRAY_LIGHTER4
  GRAY14: '#BCBCBC',
  GRAY15: '#D8D8D8',
  GRAY16: '#676767',
  GRAY17: '#747474',
  GRAY18: '#DDD3CB', // LIGHT WARM GRAY
  GRAY19: '#B6ADA5', // WARM GRAY
  GRAY20: '#F6F4F1', // GRAY5
  GRAY21: '#424143',
  GRAY22: '#4A494B',
  GRAY23: '#999999', // LIGHT GRAY for alerts
  GRAY24: '#F6F6F6',
  GRAY25: '#504F4F',
  GRAY26: '#B9BBBB',
  GRAY27: '#101820',
  GRAY28: '#6E7275',
  GRAY29: '#565A5D',
  GRAY30: '#F5F7F8',
  GRAY31: '#8C8E90',
  GRAY32: '#6e7173',
  GRAY33: '#BBBDBF',
  GRAY34: '#74777A',
  GRAY35: '#E2E4E5',
  GREEN1: '#128842', // GREEN
  GREEN2: '#0F783B', // GREEN_DARK
  GREEN3: '#A8AD00',
  GREEN4: '#54602C',
  GREEN5: '#4EA000',
  GREEN6: '#398100',
  GREEN7: '#2D8C9E',
  ORANGE1: '#ED8B00',
  ORANGE2: '#B95D13',
  ORANGE3: '#E06F00',
  ORANGE4: '#FBA14B',
  PURPLE1: '#B095CE',
  PURPLE2: '#B080A6',
  RED1: '#BF2155', // RED
  RED2: '#D0021B',
  RED3: '#CD2029',
  RED4: '#B80009',
  TEAL1: '#1C6A64',
  YELLOW1: '#F5A623',
  YELLOW2: '#E5D25C',
  WHITE_OPACITY_1: 'rgba(256, 256, 256, 0.5)', // WHITE_OPACITY_HALF
  WHITE_OPACITY_2: 'rgba(256, 256, 256, 0.15)', // WHITE_OPACITY_LIGHT
  WHITE1: '#FFF', // WHITE
  BLACK1: '#101820'
}

export const CHART_COLORS = [
  COLOR.GRAY18,
  COLOR.BLUE6,
  COLOR.GREEN3,
  COLOR.BLUE7,
  COLOR.GRAY19,
  COLOR.BLUE8,
  COLOR.BLUE9,
  COLOR.TEAL1,
  COLOR.YELLOW2,
  COLOR.ORANGE2,
  COLOR.PURPLE1,
  COLOR.GREEN4,
  COLOR.ORANGE1
]

export const FONT_FAMILY = {
  REGULAR: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
}

export const FONT_WEIGHT = {
  BOLD: 700,
  EXTRA_BOLD: 800,
  LIGHT: 300,
  REGULAR: 400,
  SEMI_BOLD: 600
}

export const Z_INDEX = {
  300: 300,
  400: 400,
  2000: 2000,
  3000: 3000,
  MAX: 9999
}

export const NO_LOGO_FONT_SIZE = {
  Header_Desktop: '30px',
  Header_Mobile: '20px',
  Table_Desktop_Mobile: '15px'
}

export const LOGO_SIZE = {
  Header_Desktop: '48px',
  Header_Mobile: '32px',
  Table_Desktop_Mobile: '24px'
}

export const OUTLINE = {
  DASHED: `
    border-radius: 4px;
    outline: 1px dashed ${COLOR.BLUE18};
  `
}
